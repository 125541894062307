<template>
    <b-modal id="actualize-rules-dialog" :size="size ? size : 'xl'"
             no-close-on-esc
             no-close-on-backdrop
             v-on:hidden="onHidden"
             v-on:ok="onOk">

        <template #modal-title>
            <font-awesome-icon :icon="['fas', 'sync']"/>
            <span class="ml-3">{{ title }}</span>
        </template>

        <div class="card" style="padding: 0; margin: 0;">

            <div class="card-header" style="text-align: center;" v-if="data">
                {{ data.view }}
            </div>

            <div class="card-body" style="padding: 0; margin: 0;">
                <table class="table table-bordered table-hover" style="table-layout: fixed; padding: 0; margin: 0;">
                    <thead>
                    <tr>
                        <th v-if="data"/>
                        <template v-for="(field,fieldIndex) in fields">
                            <th :key="'th-checkbox-'+fieldIndex" style="text-align: center;">
                                <input title="checkbox" type="checkbox" v-model="flags[field.name]"/>
                            </th>
                        </template>
                    </tr>
                    <tr>
                        <th v-if="data"/>
                        <template v-for="(field,fieldIndex) in fields">
                            <th :key="'th-label-'+fieldIndex" :class="{'st-background-green': !data && flags[field.name]}" style="text-align: center;">
                                <font-awesome-icon :icon="field.icon" v-if="field.icon"/>
                                <template v-else>
                                    {{ field.label }}
                                </template>
                            </th>
                        </template>
                    </tr>
                    </thead>
                    <tbody v-if="data">
                    <tr>
                        <td class="st-align-right" style="font-weight: bold; width: 150px;">
                            {{ $i18n.t('translations.Current') }}
                        </td>
                        <template v-for="(field,fieldIndex) in fields">
                            <td :key="'td-current-'+fieldIndex" :class="{[field.class]: true, 'st-background-green': !flags[field.name]}" style="text-align: center;">
                                {{ data.current[field.name] }}
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td class="st-align-right" style="font-weight: bold; width: 150px;">
                            {{ $i18n.t('translations.Actualized') }}
                        </td>
                        <template v-for="(field,fieldIndex) in fields">
                            <td :key="'td-actualized-'+fieldIndex" :class="{[field.class]: true, 'st-background-green': flags[field.name]}" style="text-align: center;">
                                {{ data.actualized[field.name] }}
                            </td>
                        </template>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <template #modal-footer="{ ok, cancel }">
            <b-button pill variant="success" class="pl-5 pr-5 mr-2"
                      v-on:click="ok()">
                {{ $i18n.t("translations.Actualize") }}
            </b-button>
            <b-button pill variant="dark" class="pl-5 pr-5"
                      v-on:click="cancel()">
                {{ $i18n.t("translations.Cancel") }}
            </b-button>
        </template>

    </b-modal>
</template>

<script>
export default {
    name: "ActualizeRuleDialog",
    props: ['size', 'title', 'api', 'ids', 'data', 'fields'],
    data() {
        return {
            flags: {},
        }
    },
    mounted() {
        this.fields.forEach(field => {
            this.flags = {
                ...this.flags,
                [field.name]: true
            };
        });
        this.$bvModal.show('actualize-rules-dialog');
    },
    methods: {
        onHidden() {
            this.$emit('hidden');
        },
        onOk(event) {
            event.preventDefault();
            let data = {
                'ids': this.ids,
            };
            this.fields.forEach(field => {
                data[field.name] = this.flags[field.name];
            });
            this.$http.post(this.api, data, {}).then((res) => {
                this.$emit('ok', res.data);
                this.$bvModal.hide('actualize-rules-dialog');
            }).catch((error) => {
                console.log("ActualizeRuleDialog:onOk():error:", error);
            });
        },
    }
}
</script>

<style scoped>
</style>
