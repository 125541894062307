<template>
    <b-modal id="print-document-options-dialog" size="lg" no-close-on-backdrop
             v-on:hidden="$emit('hidden')"
             v-on:ok="onOk">

        <template #modal-title>
            <font-awesome-icon :icon="['fas', 'print']" class="mr-2"/>
            {{ $i18n.t("translations.Print") }} {{ $i18n.tc("translations.Option", 2) }}
        </template>

        <div class="dialog-body">
            <fieldset class="mt-n3">

                <legend>
                    <div class="form-check form-check-inline form-control-sm">
                        <input class="form-check-input" type="radio" name="type" id="type0" value="0"
                               v-model="options.type"
                               v-on:change="onChangeType">
                        <label class="form-check-label" for="type0">
                            {{ $i18n.tc("translations.Detailed", 1) }}
                        </label>
                    </div>
                    <div class="form-check form-check-inline form-control-sm">
                        <input class="form-check-input" type="radio" name="type" id="type1" value="1"
                               v-model="options.type"
                               v-on:change="onChangeType">
                        <label class="form-check-label" for="type1">
                            {{ $i18n.tc("translations.Category only", 2) }}
                        </label>
                    </div>
                    <div class="form-check form-check-inline form-control-sm">
                        <input class="form-check-input" type="radio" name="type" id="type2" value="2"
                               v-model="options.type"
                               v-on:change="onChangeType">
                        <label class="form-check-label" for="type2">
                            {{ $i18n.tc("translations.Total for Document", 1) }}
                        </label>
                    </div>
                </legend>

                <template v-if="options.type === '0'">
                    <div class="row">
                        <div class="col-sm">
                            <div class="form-check form-control-sm">
                                <input id="with_unit_price" type="checkbox" class="form-check-input"
                                       v-model="bool_options.with_unit_price">
                                <label class="form-check-label" for="with_unit_price">
                                    {{ $i18n.tc("translations.Unit Price", 2) }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <div class="form-check form-control-sm">
                                <input id="with_subtotal" type="checkbox" class="form-check-input"
                                       v-model="bool_options.with_subtotal">
                                <label class="form-check-label" for="with_subtotal">
                                    {{ $i18n.tc("translations.Subtotal", 2) }}
                                </label>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="row mb-2" style="text-align: center; font-weight: bold;">
                        <div class="col-sm-6"/>
                        <div class="col-sm-3">
                            {{ $i18n.tc("translations.Quantity", 1) }}
                        </div>
                        <div class="col-sm-3">
                            {{ $i18n.tc("translations.Unit", 1) }}
                        </div>
                    </div>

                    <template v-if="options.type === '1'">
                        <template v-for="(section,sectionIndex) in document.sections">
                            <div :key="'section-'+sectionIndex" class="form-group row mb-2">
                                <div class="col-sm-6" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden; padding: 4px 16px;">
                                    {{ section.name }}
                                </div>
                                <div class="col-sm-3">
                                    <input type="number" :disabled="document.locked === 1"
                                           class="form-control form-control-sm"
                                           v-model="options[section.id]['quantity']"
                                           onClick="this.select();"/>
                                </div>
                                <div class="col-sm-3">
                                    <input type="text" :disabled="document.locked === 1"
                                           class="form-control form-control-sm"
                                           v-model="options[section.id]['unit_name']"
                                           onClick="this.select();"/>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="form-group row mb-2">
                            <div class="col-sm-6" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden; padding: 4px 16px;">
                                {{ $i18n.tc("translations.Total", 1) }}
                            </div>
                            <div class="col-sm-3">
                                <input type="number" :disabled="document.locked === 1"
                                       class="form-control form-control-sm"
                                       v-model="options['quantity']"
                                       onClick="this.select();"/>
                            </div>
                            <div class="col-sm-3">
                                <input type="text" :disabled="document.locked === 1"
                                       class="form-control form-control-sm"
                                       v-model="options['unit_name']"
                                       onClick="this.select();"/>
                            </div>
                        </div>
                    </template>

                    <hr/>
                </template>

                <div class="row">
                    <div class="col-sm">
                        <div class="form-check form-check-inline form-control-sm">
                            <input class="form-check-input" type="radio" name="with_discount" id="with_discount0" value="0"
                                   v-model="options.with_discount">
                            <label class="form-check-label" for="with_discount0">
                                {{ $i18n.tc("translations.No Discount", 1) }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline form-control-sm" v-if="options.type !== '2'">
                            <input class="form-check-input" type="radio" name="with_discount" id="with_discount1" value="1"
                                   v-model="options.with_discount">
                            <label class="form-check-label" for="with_discount1">
                                {{ options.type === '0' ? $i18n.tc("translations.Discount per Rule", 1) : $i18n.tc("translations.Discount per Category", 1) }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline form-control-sm">
                            <input class="form-check-input" type="radio" name="with_discount" id="with_discount2" value="2"
                                   v-model="options.with_discount">
                            <label class="form-check-label" for="with_discount2">
                                {{ $i18n.tc("translations.Discount on Total", 1) }}
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row mt-2" v-if="!document.locked">
                    <div class="col-sm">
                        <div class="form-check form-control-sm">
                            <input id="lock_document" type="checkbox" class="form-check-input"
                                   v-model="bool_options.lock_document">
                            <label class="form-check-label" for="lock_document">
                                {{ $i18n.tc("translations.Lock Document", 1) }}
                            </label>
                        </div>
                    </div>
                </div>

            </fieldset>
        </div>

        <template #modal-footer="{ ok, cancel }">
            <b-button pill variant="success" class="pl-5 pr-5 mr-2"
                      v-on:click="ok()">
                {{ choice.open_in_browser ? $i18n.t("translations.Print") : $i18n.t("translations.Download") }}
            </b-button>
            <b-button pill variant="dark" class="pl-5 pr-5"
                      v-on:click="cancel()">
                {{ $i18n.t("translations.Cancel") }}
            </b-button>
        </template>

    </b-modal>
</template>

<script>
export default {
    name: "PrintDocumentOptionsDialog",
    props: ['choice', 'document'],
    data() {
        return {
            bool_options: {
                with_unit_price: false,
                with_subtotal: false,
                lock_document: true,
            },
            options: {
                type: "0",
                quantity: this.document.summary_quantity ? this.document.summary_quantity : 0,
                unit_name: this.document.summary_unit_name ? this.document.summary_unit_name : '',
                with_discount: "0",
            },
        }
    },
    created() {
        let sections = false;
        this.document.sections.forEach(section => {
            this.options = {
                ...this.options,
                [section.id]: {
                    quantity: section.summary_quantity ? section.summary_quantity : 0,
                    unit_name: section.summary_unit_name ? section.summary_unit_name : '',
                }
            }
            if (section.summary_quantity > 0) {
                sections = true;
            }
        });
        if (sections){
            this.options.type = "1"
        } else if (this.document.summary_quantity > 0){
            this.options.type = "2"
        }
    },
    mounted() {
        this.$bvModal.show('print-document-options-dialog');
    },
    methods: {
        onChangeType() {
            if (this.options['type'] === "2" && this.options['with_discount'] === "1") {
                this.options['with_discount'] = "0";
            }
        },
        onOk() {
            for (const key of Object.keys(this.bool_options)) {
                this.options[key] = this.bool_options[key] ? 1 : 0;
            }
            this.$emit('ok', {
                choice: this.choice,
                options: this.options,
            });
        },
    }
}
</script>

<style scoped>
.dialog-body {
    font-size: 14px;
}

fieldset {
    border: 1px solid #CED4DA;
    padding: 0 1rem 1rem 1rem;
}

legend {
    width: auto;
    padding-left: 1rem;
}
</style>
